<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-user-group-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <label for="ParentUserGroup" class="form-label"
                >{{
                  $t(
                    "UserGroups.ParentUserGroup",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                :routeLink="String.format('#/UserGroup/Edit/')"
                @onChange="onChangeParentUserGroup"
                :selectedData="selectedParent"
                :isParameters="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup'
                  )
                "
              />
            </div>
            <div class="col-md-6">
              <label for="SpecificCode1" class="form-label"
                >{{
                  $t(
                    "UserGroups.SpecificCode1",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="SpecificCode1"
                v-model="userGroupData.specificCode1"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "UserGroups.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="userGroupData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="SpecificCode2" class="form-label"
                >{{
                  $t(
                    "UserGroups.SpecificCode2",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="SpecificCode2"
                v-model="userGroupData.specificCode2"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>
            <div class="col-md-6">
              <label for="Description" class="form-label"
                >{{
                  $t(
                    "UserGroups.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <textarea
                type="text"
                class="form-control"
                id="Description"
                rows="1"
                v-model="userGroupData.description"
                :spellcheck="this.$isTextSpellCheck"
              ></textarea>
            </div>
            <div class="col-md-6">
              <label for="SpecificCode3" class="form-label"
                >{{
                  $t(
                    "UserGroups.SpecificCode3",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="SpecificCode3"
                v-model="userGroupData.specificCode3"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>
            <div class="col-md-6">
              <label for="AdminIds" class="form-label"
                >{{
                  $t(
                    "UserGroups.GroupAdmin",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                @onMultipleSelectChange="onChangeGroupAdmin"
                :selectedData="selectedAdmins"
                :isMultiple="true"
                :isParameters="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryOrganizationalUnitItems?name=User'
                  )
                "
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="userGroupData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "UserGroupEdit",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      selectedParent: [],
      selectedAdmins: [],
      userGroupData: {},
    };
  },
  methods: {
    onChangeParentUserGroup(selected) {
      this.userGroupData.parentUserGroupPublicId = selected.key;
      this.userGroupData.parentUserGroupName = selected.value;
    },
    onChangeGroupAdmin(ids) {
      this.userGroupData.adminPublicIds = ids;
      // this.userGroupData.adminNames = selected.value;
    },
    getUserGroup() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-GetUserGroup?id={0}",
            this.$route.params.userGroupId
          )
        )
        .then((response) => {
          this.userGroupData = response.data;

          if (
            !String.isNullOrWhiteSpace(response.data.adminPublicIds) &&
            !String.isNullOrWhiteSpace(response.data.adminNames)
          ) {
            var listAdminIds = response.data.adminPublicIds;
            var listNames = response.data.adminNames;
            var self = this;
            if (
              listAdminIds.includes(this.$systemSeparator) &&
              listNames.includes(this.$systemSeparator)
            ) {
              listAdminIds = listAdminIds.split(this.$systemSeparator);
              listNames = listNames.split(this.$systemSeparator);
              $.each(listAdminIds, function (i, v) {
                if (v !== "" && listNames !== "") {
                  self.selectedAdmins.push({
                    key: v,
                    value: listNames[i],
                  });
                }
              });
            } else {
              self.selectedAdmins.push({
                key: listAdminIds,
                value: listNames,
              });
            }
          }

          if (
            !String.isNullOrWhiteSpace(response.data.parentUserGroupPublicId) &&
            !String.isNullOrWhiteSpace(response.data.parentUserGroupName)
          ) {
            this.selectedParent.push({
              key: response.data.parentUserGroupPublicId,
              value: response.data.parentUserGroupName,
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-user-group-edit");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-UpdateUserGroup", { ...this.userGroupData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/UserGroup/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getUserGroup();
  },
};
</script>
